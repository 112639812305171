<template>
  <div class="modal-backdrop" v-if="showRegisterModal">
    <div class="modal-register">
      <div class="modal-register__heading">
        <div class="modal-register__heading--text">
          <h3>Registrarse</h3>
          <button class="close-button" @click="closeRegisterModal">
            <span class="material-symbols-rounded">cancel</span>
          </button>
        </div>
      </div>
      <div class="modal-register__content">
        <div class="modal-register__heading--img">
          <img src="../../src/assets/images/logo.png" alt="empelotados">
          <p>Por favor complete todos los campos de este formulario</p>
          <div class="modal-register__heading--lock">
            <span class="material-symbols-rounded">lock</span>
            <p>Información de seguridad</p>
          </div>
        </div>
        <!-- <div class="modal-content__img">
          <img src="../../src/assets/images/modal-register-img.png" alt="">
        </div> -->
        <div class="modal-content__iframe">
          <iframe class="iframe-style" id="iframeRegistroD" title="Registro Iframe" width="100%" height="100%"
            :src="`${baseUrl}/signup?domain=${BackEndUrl}&lang=es`">
          </iframe>
        </div>
      </div>
    </div>
    <ModalComponent />
  </div>
</template>

<script>
import ModalComponent from '@/components/modal.vue';

export default {
  name: 'ModalRegister',

  setup: () => {
    const baseUrl = 'https://signup.isppro.net';
    const BackEndUrl = 'empelotados.mx';

    return {
      baseUrl,
      BackEndUrl
    };
  },

  data() {
    return {
      showRegisterModal: false,
    };
  },

  components: {
    ModalComponent,
  },
  
  methods: {
    openRegisterModal() {
      this.showRegisterModal = true;
      this.$nextTick(() => {
        this.iframeCookies();
      });
    },
    closeRegisterModal() {
      this.showRegisterModal = false;
    },
    
    iframeCookies() {
      let frame = document.getElementById('iframeRegistroD');
      let cookies = {};
      document.cookie.split(';').forEach((x) => {
        if (x.includes('affiliateCode')) {
          cookies.affiliateCode = x.split('=')[1];
        }
        if (x.includes('bannerCode')) {
          cookies.bannerCode = x.split('=')[1];
        }
        if (x.includes('campaign')) {
          cookies.campaign = x.split('=')[1];
        }
        if (x.includes('clickcode')) {
          cookies.clickcode = x.split('=')[1];
        }
      });

      if (frame && cookies && cookies.affiliateCode && cookies.bannerCode && cookies.campaign) {
        let url = frame.src.includes('?') ?
          `${frame.src}&affiliateCode=${cookies.affiliateCode}&bannerCode=${cookies.bannerCode}&campaign=${cookies.campaign}` :
          `${frame.src}?affiliateCode=${cookies.affiliateCode}&bannerCode=${cookies.bannerCode}&campaign=${cookies.campaign}`;

        if (cookies.clickcode && cookies.clickcode != 0)
          url += `&clickcode=${cookies.clickcode}`;

        frame.src = url;
      }
      return cookies;
    },
  },
};
</script>
