<template>
  <div class="clock">
    <span class="material-symbols-rounded">schedule</span>
    <p>{{ currentTime }} (GMT-6)</p>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "ClockComponent",
  data() {
    return {
      currentTime: ""
    };
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000); // Actualizar cada segundo
  },
  methods: {
    updateTime() {
      const timeZone = "America/Mexico_City"; // Zona horaria de Ciudad de México
      const now = moment().tz(timeZone).format("HH:mm:ss");
      this.currentTime = now;
    }
  }
};
</script>
