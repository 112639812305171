<template>
  <menuMobile></menuMobile>
  <header-component/>
  <router-view/>
  <footer-component/>
  <chat-component/>
</template>

<script>
import headerComponent from "@/components/header.vue"
import FooterComponent from "@/components/footer.vue"
import menuMobile from"@/components/mobile-menu.vue"
import chatComponent from "./components/chat-component.vue"

export default {
  name: 'App',
  components: {
    headerComponent,
    FooterComponent,
    menuMobile,
    chatComponent
  },
  beforeUnmount() {
    window.onbeforeunload = () => null;
  }
}
</script>
