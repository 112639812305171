<template>
  <caption></caption>
</template>

<script>
export default {
  name: "ChatComponent",

  mounted() {
    var Tawk_API=Tawk_API||{};
    (function(){
      var s1=document.createElement("script"), s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/64dbde5f94cf5d49dc6a9894/1h7tdp9su';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    })();
  },
}
</script>