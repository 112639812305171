<template>
  <div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">
    <div class="mobile-menu__container">
      <router-link class="mobile-menu__item" to="/deportes">
        <img class="mobile-menu__item-icon" src="../assets/images/menu-mobile/deportes.png" alt="">
        <span>Deportes</span>
      </router-link>
      <router-link class="mobile-menu__item" to="/casino">
        <img class="mobile-menu__item-icon" src="../assets/images/menu-mobile/casino.png" alt="">
        <span>Casino</span>
      </router-link>
      <router-link class="mobile-menu__item" to="/casino-en-vivo">
        <img class="mobile-menu__item-icon" src="../assets/images/menu-mobile/casino-vivo.png" alt="">
        <span>En vivo</span>
      </router-link>
      <a class="mobile-menu__item" href="https://tawk.to/chat/64dbde5f94cf5d49dc6a9894/1h7tdp9su">
        <span class="mobile-menu__item-icon material-symbols-rounded">support_agent</span>
        <span>Ayuda</span>
      </a>
      <!-- <router-link class="mobile-menu__item" to="/">
        <img class="mobile-menu__item-promo" src="../assets/images/menu-mobile/esports.png" alt="">
        <span>E-Sports</span>
      </router-link> -->
    </div>
    <menu-component />
  </div>
</template>
  
<script>

export default {
  name: 'MobileMenu',
  showComponent: true,
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  }
};
</script>