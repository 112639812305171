import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/deportes',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/casino',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/caballos',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/reglas-casa',
    component: () => import('../pages/reglas-casa.vue'),  
  },
  {
    path: '/sports-rules',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/responsible-gaming',
    component: () => import('../pages/responsible-gaming.vue'),  
  },
  {
    path: '/forgot-password',
    component: () => import('../pages/forgot-password.vue'),  
  },
  {
    path: '/props-builder',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/promotions',
    component: () => import('../pages/promotions.vue'),  
  },
  {
    path: '/registrarme',
    component: () => import('../pages/registrarme.vue'),  
  },
  {
    path: '/juegos-casino',
    component: () => import('../pages/juegos-casino.vue'),  
  },
  {
    path: '/bingo',
    component: () => import('../pages/bingo.vue'),  
  },
  {
    path: '/e-sports',
    component: () => import('../pages/e-sports.vue'),  
  },
  {
    path: '/reglas-casino',
    component: () => import('../pages/reglas-casino.vue'),  
  },
  {
    path: '/juego-responsable',
    component: () => import('../pages/juego-responsable.vue'),  
  },
  {
    path: '/auto-exclusion',
    component: () => import('../pages/auto-exclusion.vue'),  
  },
  {
    path: '/politica-retiradas',
    component: () => import('../pages/politica-retiradas.vue'),  
  },
  {
    path: '/anti-lavado',
    component: () => import('../pages/anti-lavado.vue'),  
  },
  {
    path: '/juego-justo',
    component: () => import('../pages/juego-justo.vue'),  
  },
  {
    path: '/politica-privacidad',
    component: () => import('../pages/politica-privacidad.vue'),  
  },
  {
    path: '/politica-cookies',
    component: () => import('../pages/politica-cookies.vue'),  
  },
  {
    path: '/terminos-condiciones',
    component: () => import('../pages/terminos-condiciones.vue'),  
  },
  {
    path: '/preguntas-frecuentes',
    component: () => import('../pages/preguntas-frecuentes.vue'),  
  },
  {
    path: '/pagos-retiros',
    component: () => import('../pages/pagos-retiros.vue'),  
  },
  {
    path: '/metodos-pago',
    component: () => import('../pages/pay-methods.vue'),  
  },
  {
    path: '/nuevo-usuario',
    component: () => import('../pages/new-user-promotion.vue'),  
  },
  // {
  //   path: '/bienvenida-apuesta-deportiva',
  //   component: () => import('../pages/bienvenida-apuesta-deportiva.vue'),  
  // },
  // {
  //   path: '/bienvenida-casino',
  //   component: () => import('../pages/bienvenida-casino.vue'),  
  // },
  {
    path: '/recompensa-bono',
    component: () => import('../pages/recompensa-bono.vue'),  
  },
  // {
  //   path: '/bono-final-liga',
  //   component: () => import('../pages/bono-final-liga.vue'),  
  // },
  // {
  //   path: '/bono-deportes',
  //   component: () => import('../pages/bono-deportes.vue'),  
  // },
  // {
  //   path: '/noche-box',
  //   component: () => import('../pages/noche-box.vue'),  
  // },
  // {
  //   path: '/bono-redencion',
  //   component: () => import('../pages/bono-redencion.vue'),  
  // },
  // {
  //   path: '/bono-recupera',
  //   component: () => import('../pages/bono-recupera.vue'),  
  // },
  // {
  //   path: '/bono-experiencias',
  //   component: () => import('../pages/bono-experiencias.vue'),  
  // },
  {
    path: '/bono-recarga',
    component: () => import('../pages/bono-recarga.vue'),  
  },
  {
    path: '/bono-cumple',
    component: () => import('../pages/bono-cumple.vue'),  
  },
  {
    path: '/bono-primer-deposito',
    component: () => import('../pages/bono-primer-deposito.vue'),  
  },
  {
    path: '/bono-primer-deposito-casino',
    component: () => import('../pages/bono-primer-deposito-casino.vue'),  
  },
  {
    path: '/bono-navidad',
    component: () => import('../pages/bono-navidad.vue'),  
  },
  {
    path: '/casino-en-vivo',
    component: () => import('../pages/casino-en-vivo.vue'),  
  },
  {
    path: '/mapa-sitio',
    component: () => import('../pages/mapa-sitio.vue'),  
  },
  {
    path: "/not-found",
    component: () => import('../pages/erros/error-404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect:'not-found'
  },
  {
    path: '/reglas-de-parlay-del-mismo-equipo',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})
export default router